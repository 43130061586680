import { projectFirestore } from '@/firebase/config';

// populate in VueX store
const firebaseGetCollection = async (collection, overload) => {
  const res = { results: [], error: null };

  let collectionRef = await projectFirestore.collection(collection);

  // Overload
  if (overload) {
    // Sort
    if (overload.sort) {
      collectionRef.orderBy(overload.sort);
    }

    // Filter
    if (overload.query) {
      collectionRef = collectionRef.where(...overload.query);
    }
  }

  // Real time listener (sends latest snap of collection)
  collectionRef.onSnapshot((snap) => {
    res.results = [];
    snap.docs.forEach((doc) => {
      if (doc.data()) res.results.push({ ...doc.data(), id: doc.id });
    });
  }, (err) => {
    res.error = err.message;
  });

  return res;
};

export default firebaseGetCollection;
