//! ! Note: Enter your firebaseConfig details below and rename to config.js

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBL0u3CnSu8ZNrXasDFv_ZddCByoAWRNS8',
  authDomain: 'portfolio-8c324.firebaseapp.com',
  projectId: 'portfolio-8c324',
  storageBucket: 'portfolio-8c324.appspot.com',
  messagingSenderId: '486220004115',
  appId: '1:486220004115:web:cb8bc5d2b2a086e575b8e6',
  measurementId: 'G-QZ3ZMRCL9X',
};

// Init firebase
firebase.initializeApp(firebaseConfig);

// Init auth
const projectAuth = firebase.auth();

// Init firestore service (can invoke projectFirestore as a function now (like db.conn() in .net))
const projectFirestore = firebase.firestore();

// Init timestamps (can invoke timestamp as a function now)
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectAuth, projectFirestore, timestamp };
