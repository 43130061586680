<template>
  <nav v-if="navigationCollection && personalCollection">
    <!-- Navbar -->
    <v-app-bar
      app
      class="grey darken-4"
      dark
      height="60px"
    >
      <v-app-bar-nav-icon
        app @click="drawer = !drawer"
      >
        <v-icon v-if="!drawer">mdi-menu</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- Title -->
      <v-toolbar-title
        class="d-flex"
      >
        <div class="d-flex align-center">
          <span class="hidden-sm-and-down">
            {{ personalCollection.firstName + ' ' + personalCollection.lastName }}
          </span>
        </div>
        <v-img
          :src="personalCollection.profileLogo.url"
          alt="Logo"
          class="shrink ml-2"
          contain
          transition="scale-transition"
          width="40"
        />
      </v-toolbar-title>
    </v-app-bar>

    <!-- Navdraw -->
    <v-navigation-drawer
      app
      class="grey darken-4"
      dark
      v-model="drawer"
      max-height="100vh"
    >
      <v-row>
        <v-col align="center">
          <v-flex class="mt-5">
            <v-avatar size="150">
              <v-img :src="personalCollection.profilePicture.url"/>
            </v-avatar>
          </v-flex>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list>
            <template v-for="(link, index) in navigationCollection.links">
              <v-list-item
                v-if="link.isAllowed"
                :key="index"
                router :to="{name: `${link.name}`}"
                exact
              >
                <!-- Icon -->
                <v-list-item-action>
                  <v-icon class="white--text">{{link.icon}}</v-icon>
                </v-list-item-action>

                <!-- Content -->
                <v-list-item-content class="white--text">
                  <v-list-item-title>
                    {{link.name}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-col class="force-bottom" align="center">
        <div>
          <template v-for="(social, index) in navigationCollection.socials">
             <v-btn
              v-if="social.isAllowed"
              :key="index"
              icon class="mx-1"
              target="_blank" :href="social.url"
            >
              <v-icon large>{{social.icon}}</v-icon>
            </v-btn>
          </template>
        </div>
        <div class="mt-3">
          © {{
            personalCollection.firstName +
            ' ' + personalCollection.lastName +
            ' ' + new Date().getFullYear()
          }}
        </div>
      </v-col>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';

export default {
  name: 'Navbar',

  data() {
    return {
      drawer: false,
      navigationCollectionRaw: null,
      personalCollectionRaw: null,
    };
  },

  computed: {
    navigationCollection() {
      return (this.navigationCollectionRaw && this.navigationCollectionRaw.results)
        ? this.navigationCollectionRaw.results[0]
        : null;
    },

    personalCollection() {
      return (this.personalCollectionRaw && this.personalCollectionRaw.results)
        ? this.personalCollectionRaw.results[0]
        : null;
    },
  },

  async created() {
    await this.subToCollection();
  },

  destroyed() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.navigationCollectionRaw = await firebaseGetCollection('navigation');
      this.personalCollectionRaw = await firebaseGetCollection('personal');
    },

    unSubToCollection() {
      this.navigationCollectionRaw = null;
      this.personalCollectionRaw = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .force-bottom{
    position: absolute !important;
    bottom: 5px !important;
    left: 0 !important;
  }
</style>
