import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Index.vue'),
  },
  {
    path: '/skills',
    name: 'Skills',
    component: () => import('@/views/skills/Index.vue'),
  },
  {
    path: '/experience',
    name: 'Experience',
    component: () => import('@/views/experience/Index.vue'),
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('@/views/portfolio/Index.vue'),
  },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: () => import('@/views/contact/Index.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
});

const DEFAULT_TITLE = 'CW - Portfolio';
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
