<template>
  <v-app id="main-container" v-if="appCollection && !appCollection.isHidden">
    <NavBar/>
    <v-main class="pb-5">
      <transition
        mode="out-in"
        @before-enter="routeBeforeEnter"
        @enter="RouteEntered"
        @leave="RouteAfterLeft"
      >
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';
import gsap from 'gsap';
import NavBar from './views/_partials/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  },

  data: () => ({
    appCollectionRaw: null,
  }),

  computed: {
    appCollection() {
      return (this.appCollectionRaw && this.appCollectionRaw.results)
        ? this.appCollectionRaw.results[0]
        : null;
    },
  },

  async created() {
    await this.subToCollection();
  },

  destroyed() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.appCollectionRaw = await firebaseGetCollection('app');
    },

    unSubToCollection() {
      this.appCollectionRaw = null;
    },

    // Animations using GSAP
    routeBeforeEnter(el) {
      gsap.from(el, {
        opacity: 0,
        x: '100px',
      });
    },
    RouteEntered(el, done) {
      gsap.to(el, {
        duration: 0.5,
        x: 0,
        opacity: 1,
        onComplete: done,
      });
    },
    RouteAfterLeft(el, done) {
      gsap.to(el, {
        duration: 0.5,
        x: '-100px',
        opacity: 0,
        onComplete: done,
      });
    },
  },
};
</script>

<style lang="scss">
  #main-container {
    background: #2C2D2F !important;
    color: #e1e1e1 !important;
  }

  ul li {
    list-style: none !important;
  }

  a, a:hover {
    text-decoration: none;
  }

  .btn-primary {
    background-color: #2C2D2F !important;
    border: 2px solid #5078FF;
    color: #5078FF !important;
  }

  .btn-primary:hover {
    background-color: #5078FF !important;
    color: #2C2D2F !important;
  }

  .btn-outline {
    background-color: rgba(0,0,0,0.1) !important;
    border: 2px solid #5078FF;
    border-radius: 7px !important;
    color: #5078FF !important;
  }

  .btn-outline:hover {
    background-color: rgba(0,0,0,1) !important;
    color: #4e71e2 !important;
  }

  .mouse-pointer:hover {
    cursor: pointer;
  }
</style>
